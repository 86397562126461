.control-label{
  text-transform: uppercase;
}

.form-group{
  padding: 10px;
  .form-control{
    height: 40px;
    margin: 5px 0;
  }
  textarea.form-control{
    height: 140px;
  }
}

.navbar-inverse .navbar-nav > li > a{
  color: $primary;
}


.btn-search {
  float: right;
  margin-top: 25px;
  padding: 6.7px 15px !important;
}

.btn-primary, .btn-search, .btn-info{
  background: $primary;
  border: 1px solid $primary;

  &:hover,
  &:focus,
  &:active {
    background: darken($primary, 10%);
  }
}

.input-group-text {
  height: 40px;
  margin: 5px 0;
}

.button-form{
  float: right;
}

.label-success {
  background: $accent-color;
}

.export-form, .add-form {
  display: inline-block;
}

textarea {
  resize: vertical;
}

.list-group-bare {
  .list-group-item {
    background: unset;
    border: unset;
    padding: unset;
  }
}

.strikethrough {
    text-decoration: line-through;
}

.notice {
  font-size: .8rem;
  width: 100%;
  text-align: center;
}
.main-navbar {
  background-color: $header-color;
  padding: 15px 0;

  &__user {
    text-transform: uppercase;
  }
}

.navbar-brand {
  padding-top: 5px;
  padding-bottom: 7px;
}

.side-nav__search {
  color: $primary;
  border-top: 1px solid $primary;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
}

.search-box {
  width: 100%;
}

.side-nav__radio {
  font-weight: 300;
  font-size: 11px;
  text-transform: uppercase;
}

.side-nav {
  background-color: $accent-color;
  box-shadow: 4px 0 6px rgba(0, 0, 0, .05);
  top: 81px;

  &__user {
    margin-top: 15px;
    margin-bottom: 3px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__status {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 10px;
  }

  &__logout {
    &,
    &:hover {
      color: $primary;
    }

    &:hover {
      background: transparent;
      text-decoration: underline;
    }
  }

  &__title {
    color: $nevada;
    font-weight: 300;
    font-size: 13px;
    padding: 15px;
    text-transform: uppercase;
  }

  &__link {
    color: $primary;
    cursor: pointer;

    &:hover {
      background-color: $primary !important;
      color: $white !important;
    }
  }

  &__active {
    background-color: $primary;

    a {
      color: $white !important;
    }
  }

  &__avatar {
    border-bottom: 1px solid $primary;
    margin-bottom: 20px;
    padding: 20px;
  }
}
